import React, { useContext, useEffect, useState } from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { collection, onSnapshot, query, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";

import PropertyContext from "../contexts/PropertyContext";

const GenerateDRList = () => {
  const [itemsByCategory, setItemsByCategory] = useState({});
  const [loading, setLoading] = useState(false);
  const { selectedProperty, rooms, items, itemCategories } =
    useContext(PropertyContext);

  const generateExcelDRL = async () => {
    console.log("generateDRL called");
    console.log(rooms);
    console.log("..................................");
    console.log(items);

    const workbook = new ExcelJS.Workbook();
    const mainWorksheet = workbook.addWorksheet("DRL Overview");

    // Create columns dynamically based on item names
    const itemColumns = items.map((item) => ({
      header: item.itemName,
      key: item.itemName,
      width: 20,
    }));

    const vendors = [
      { key: "vendorElectricalRepair", expectedCost: 500 },
      { key: "vendorPestControl", expectedCost: 350 },
      { key: "vendorPlumbingRepair", expectedCost: 200 },
      { key: "vendorSubfloor", expectedCost: 1200 },
      { key: "vendorTubResurface", expectedCost: 600 },
      { key: "vendorWindow", expectedCost: 500 },
    ];

    // Add the 'Room' column at the beginning
    mainWorksheet.columns = [
      { header: "Room", key: "room", width: 20 },
      ...itemColumns,
      ...vendors.map((vendor) => ({
        header: vendor.key.replace("vendor", "").trim(), // Format the header for readability
        key: vendor.key,
        width: 20,
      })),
      { header: "Total", key: "total", width: 20 }, // Add Total column
      { header: "Unit Notes", key: "unitNotes", width: 50 }, // Add Unit Notes column with a wider width
    ];

    mainWorksheet.getRow(1).font = { bold: true };
    mainWorksheet.getRow(1).alignment = { horizontal: "center" };
    mainWorksheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFD3D3D3" },
    };

    // Filter rooms to only include those with status 'Down'
    const downRooms = rooms.filter((room) => room.status === "Down");

    // Populate the main worksheet with room data
    for (const room of downRooms) {
      // Create a new worksheet for each room
      const roomWorksheet = workbook.addWorksheet(room.displayName);
      const rowData = {
        room: {
          text: room.displayName,
          hyperlink: `#'${room.displayName}'!A1`,
        },
      };
      // Calculate the total cost for this row
      let totalCost = 0;

      items.forEach((item) => {
        rowData[item.itemName] = room[item.itemID] || ""; // Use item.itemID to check if it exists in the room object
      });

      items.forEach((item) => {
        const itemCountKey = item.itemID; // Key to check for the item count in the room object
        const itemCount = parseFloat(room[itemCountKey] || 0); // Get item count (default to 0)
        const itemCost = parseFloat(item.itemCost || 0); // Get the item's unit cost (default to 0)

        const itemTotalCost = itemCount * itemCost; // Calculate total cost for this item
        rowData[item.itemName] = itemCount > 0 ? itemCount : ""; // Display total cost if applicable

        totalCost += itemTotalCost; // Accumulate the total cost for the room
      });

      // Add vendor requirements and costs
      vendors.forEach((vendor) => {
        const vendorCount = parseInt(room[vendor.key] || 0, 10); // Get vendor count (default to 0)
        rowData[vendor.key] = vendorCount > 0 ? vendorCount : ""; // Display the count if greater than 0

        const vendorCost = vendorCount * vendor.expectedCost; // Calculate total cost for this vendor
        totalCost += vendorCost; // Add vendor cost to total cost
      });

      rowData.total = totalCost; // Add the total cost to the row

      rowData.unitNotes = room.unitNotes || ""; // Add unitNotes if present, otherwise leave blank

      mainWorksheet.addRow(rowData);

      // Add columns to the room worksheet
      roomWorksheet.columns = [
        { header: "Room", key: "room", width: 20 },
        ...itemColumns,
      ];
      roomWorksheet.getRow(1).font = { bold: true };
      roomWorksheet.getRow(1).alignment = { horizontal: "center" };
      roomWorksheet.getRow(1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFD3D3D3" },
      };

      // Add room data to the room worksheet
      const roomRowData = { room: room.displayName };
      items.forEach((item) => {
        roomRowData[item.itemName] = room[item.itemID] || "";
      });
      roomWorksheet.addRow(roomRowData);

      // Add image links to the room worksheet
      const imagesRef = collection(
        firestore,
        "properties",
        selectedProperty,
        "units",
        room.id,
        "images"
      );

      // original code to just inject the image URL as a hyperlink
      const imagesQuerySnapshot = await getDocs(query(imagesRef));
      let imageRow = 3;
      for (const doc of imagesQuerySnapshot.docs) {
        const image = doc.data();
        roomWorksheet.addRow([
          `Uploaded at: ${image.uploadedAt}`,
          `Link: ${image.url}`,
        ]);
        const cell = roomWorksheet.getCell(`B${imageRow}`);
        cell.value = {
          text: "View Image",
          hyperlink: image.url,
        };
        cell.font = { color: { argb: "FF0000FF" }, underline: true };
        imageRow += 1;
      }

      console.log("getting images");
      // code to inject image as image
      // const imagesQuerySnapshot = await getDocs(query(imagesRef));
      // let imageRow = 3;
      // for (const doc of imagesQuerySnapshot.docs) {
      //   const image = doc.data();
      //   roomWorksheet.addRow([
      //     `Uploaded at: ${image.uploadedAt}`,
      //     `Link: ${image.url}`,
      //   ]);

      //   // Load image from URL
      //   const response = await fetch(image.url);
      //   const arrayBuffer = await response.arrayBuffer();
      //   const base64String = arrayBufferToBase64(arrayBuffer);

      //   // Determine the extension of the image
      //   const extension = image.url.split(".").pop().toLowerCase();

      //   // Add image to workbook
      //   const imageId = roomWorksheet.workbook.addImage({
      //     base64: base64String,
      //     extension: extension,
      //   });

      //   // Add image to cell
      //   roomWorksheet.addImage(imageId, {
      //     tl: { col: 1, row: imageRow - 1 },
      //     ext: { width: 100, height: 100 }, // Adjust the size as needed
      //   });

      //   imageRow += 1;
      //   console.log(`imaged added for room ${room.id}`);
      // }

      // Helper function to convert ArrayBuffer to base64
      function arrayBufferToBase64(buffer) {
        console.log("arrayBufferToBase64 Called");
        let binary = "";
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
      }
    }

    mainWorksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });

    console.log("got here");
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    console.log("got here 2");
    const today = new Date();
    const date = today.toISOString().split("T")[0];
    const dateStr = date.replace(/-/g, "_");
    //saving the file
    console.log("got here 3");
    saveAs(blob, `Generated_DRL_${dateStr}.xlsx`);
  };

  const groupItemsByType = (items) => {
    console.log("groupItemsByType called");
    return items.reduce((acc, item) => {
      if (!acc[item.itemType]) {
        acc[item.itemType] = [];
      }
      acc[item.itemType].push(item);
      return acc;
    }, {});
  };

  useEffect(() => {
    setLoading(true);
    const groupedItems = groupItemsByType(items);

    const newItemType = "Vendor Needed";
    if (!groupedItems[newItemType]) {
      groupedItems[newItemType] = [];
    }

    const vendorsList = [
      { itemID: "vendorElectricalRepair", itemName: "Electrical Repair" },
      { itemID: "vendorPestControl", itemName: "Pest Control" },
      { itemID: "vendorPlumbingRepair", itemName: "Plumbing Repair" },
      { itemID: "vendorTubResurface", itemName: "Tub Resurface" },
      { itemID: "vendorWindow", itemName: "Window" },
    ];

    vendorsList.forEach((item) => {
      groupedItems[newItemType].push({
        ...item,
        itemType: newItemType,
      });
    });

    setItemsByCategory(groupedItems);

    setLoading(false);
  }, [selectedProperty]);

  return (
    <div>
      <button onClick={generateExcelDRL} disabled={loading}>
        {loading ? "Generating..." : "Generate DRL"}
      </button>
    </div>
  );
};

export default GenerateDRList;
