import React, { useContext, useEffect, useState, useRef } from "react";
import { Button, ThemeProvider, createTheme } from "@mui/material";
import jsPDF from "jspdf";
import { PieChart } from "@mui/x-charts";
import html2canvas from "html2canvas";
import PropertyContext from "../contexts/PropertyContext";
import { useAuth } from "../AuthContext";

const chartScale = 90;

const GenerateRoomsChart = ({ user }) => {
  const { rooms, items, propertyInformation } = useContext(PropertyContext);
  const [groupedItems, setGroupedItems] = useState({});
  const { currentUser } = useAuth();
  const turnableRef = useRef(null);
  const vendorRef = useRef(null);
  const roomsByItemTypeNeededRef = useRef(null);
  const [displayChart, setDisplayChart] = useState(false);
  console.log(user);
  console.log(propertyInformation);

  const vendorsList = [
    { itemID: "vendorElectricalRepair", itemName: "Electrical Repair" },
    { itemID: "vendorPestControl", itemName: "Pest Control" },
    { itemID: "vendorPlumbingRepair", itemName: "Plumbing Repair" },
    { itemID: "vendorTubResurface", itemName: "Tub Resurface" },
    { itemID: "vendorWindow", itemName: "Window" },
    { itemID: "vendorSubfloor", itemName: "Subfloor" },
  ];

  useEffect(() => {
    const groupItemsByType = (items) => {
      return items.reduce((acc, item) => {
        if (!acc[item.itemType]) {
          acc[item.itemType] = [];
        }
        acc[item.itemType].push(item);
        return acc;
      }, {});
    };

    const groupedItemsTemp = groupItemsByType(items);

    // Exclude vendors list from grouped items
    vendorsList.forEach((vendor) => {
      delete groupedItemsTemp[vendor.itemID];
    });

    setGroupedItems(groupedItemsTemp);
  }, [items]);

  const getTurnableRooms = () => {
    // Extract itemIDs from vendorsList
    const vendorItemIDs = vendorsList.map((vendor) => vendor.itemID);

    // Filter rooms based on the criteria
    return rooms.filter((room) => {
      // Check if room is "down"
      if (room.status.toLowerCase() !== "down") {
        return false;
      }

      // Check if any vendor itemID in the room has a value greater than 0
      for (const itemID of vendorItemIDs) {
        if (room[itemID] > 0) {
          return false;
        }
      }

      // If none of the vendor itemIDs have a value greater than 0, the room is turnable
      return true;
    }).length;
  };
  const listTurnableRooms = () => {
    // Extract itemIDs from vendorsList
    const vendorItemIDs = vendorsList.map((vendor) => vendor.itemID);

    // Filter rooms based on the criteria
    return rooms.filter((room) => {
      // Check if room is "down"
      if (room.status.toLowerCase() !== "down") {
        return false;
      }

      // Check if any vendor itemID in the room has a value greater than 0
      for (const itemID of vendorItemIDs) {
        if (room[itemID] > 0) {
          return false;
        }
      }

      // If none of the vendor itemIDs have a value greater than 0, the room is turnable
      return true;
    });
  };
  console.log(listTurnableRooms());

  const getUnTurnableRooms = () => {
    // Extract itemIDs from vendorsList
    const vendorItemIDs = vendorsList.map((vendor) => vendor.itemID);

    // Filter rooms based on the criteria
    return rooms.filter((room) => {
      // Check if room is "down"
      if (room.status.toLowerCase() !== "down") {
        return false;
      }

      // Check if any vendor itemID in the room has a value greater than 0
      for (const itemID of vendorItemIDs) {
        if (room[itemID] > 0) {
          return true;
        }
      }

      // If none of the vendor itemIDs have a value greater than 0, the room is turnable
      return false;
    }).length;
  };

  const getVendorRequiredRooms = () => {
    const vendorCounts = {
      Electrical: 0,
      PestControl: 0,
      Plumbing: 0,
      TubResurface: 0,
      Window: 0,
      Subfloor: 0,
    };

    rooms.forEach((room) => {
      if (room.status.toLowerCase() === "down") {
        if (room.vendorElectricalRepair > 0) vendorCounts.Electrical++;
        if (room.vendorPestControl > 0) vendorCounts.PestControl++;
        if (room.vendorPlumbingRepair > 0) vendorCounts.Plumbing++;
        if (room.vendorTubResurface > 0) vendorCounts.TubResurface++;
        if (room.vendorWindow > 0) vendorCounts.Window++;
        if (room.vendorSubfloor > 0) vendorCounts.Subfloor++;
      }
    });

    return vendorCounts;
  };

  const getRoomsByItemTypeNeeded = () => {
    const roomsByItemTypeNeeded = {};

    rooms.forEach((room) => {
      if (room.status.toLowerCase() === "down") {
        const vendorItemIDs = vendorsList.map((vendor) => vendor.itemID);

        // Skip the room if any vendor itemID has a value greater than 0
        const hasVendorItems = vendorItemIDs.some((itemID) => room[itemID] > 0);
        if (hasVendorItems) {
          return;
        }

        // If the room is turnable, check which item types the room needs
        Object.keys(groupedItems).forEach((itemType) => {
          //   console.log(`itemType: ${itemType}`);
          if (groupedItems[itemType].some((item) => room[item.itemID] > 0)) {
            if (!roomsByItemTypeNeeded[itemType]) {
              console.log("attempting to add item type: ", itemType);
              roomsByItemTypeNeeded[itemType] = 0;
            }
            console.log("incrementing item type: ", itemType);
            roomsByItemTypeNeeded[itemType]++;
          }
        });
      }
    });

    return roomsByItemTypeNeeded;
  };

  const createTableBody = (vendorsList, rooms) => {
    return vendorsList.map((vendor) => {
      const relevantRooms = rooms
        .filter((room) => room.status.toLowerCase() === "down")
        .filter((room) => room[vendor.itemID] > 0)
        .map((room) => room.displayName)
        .join(", ");

      return [vendor.itemName, relevantRooms];
    });
  };

  const generatePDF = async () => {
    try {
      setDisplayChart(true);
      const roomsByItemTypeNeeded = getRoomsByItemTypeNeeded();

      console.log(roomsByItemTypeNeeded);

      // Wait for the charts to be displayed
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const pdf = new jsPDF();
      const pageWidth = pdf.internal.pageSize.getWidth();
      const fontSize = 12;
      pdf.setFontSize(fontSize);
      const margin = 5;

      const turnableCanvas = await html2canvas(turnableRef.current);
      const vendorCanvas = await html2canvas(vendorRef.current);
      const roomsByItemTypeNeededCanvas = await html2canvas(
        roomsByItemTypeNeededRef.current
      );

      const turnableChartURL = turnableCanvas.toDataURL("image/png");
      const vendorChartURL = vendorCanvas.toDataURL("image/png");
      const roomsByItemTypeNeededChartURL =
        roomsByItemTypeNeededCanvas.toDataURL("image/png");

      // Calculate the width of the text
      const titleText = `Down Rooms Charts`;

      const rightAlign = (text, ypos) => {
        const textWidth = pdf.getTextWidth(text);
        const xpos = pageWidth - textWidth - margin;

        pdf.text(text, xpos, ypos);
      };

      const leftAlign = (text, ypos) => {
        pdf.text(text, margin, ypos);
      };

      const centerAlign = (text, ypos) => {
        const textWidth = pdf.getTextWidth(text);
        const xpos = (pageWidth - textWidth) / 2;

        pdf.text(text, xpos, ypos);
      };

      const centerAlignImageArray = (imageArray, ypos) => {
        const totalWidth = imageArray.reduce(
          (acc, image) => acc + image.width,
          0
        );
        const startX = (pageWidth - totalWidth) / 2;
        let currentX = startX; // Use a different variable for iteration

        imageArray.forEach((image) => {
          // Add the image
          pdf.addImage(
            image.url,
            "PNG",
            currentX,
            ypos,
            image.width,
            image.height
          );

          // Calculate center position for the text
          const textX = currentX + image.width / 2;
          const textY = ypos + image.height + 10; // Adjust as needed for spacing

          // Add the text (subtitle)
          if (image.subtitle) {
            pdf.text(image.subtitle, textX, textY, { align: "center" });
          }

          currentX += image.width; // Increment currentX instead of startX
        });
      };

      // Center the text
      centerAlign(titleText, 10);

      //right align user information
      rightAlign(`${user.fullName}`, 10);
      rightAlign(`${new Date().toLocaleDateString()}`, 14);
      rightAlign(`${new Date().toLocaleTimeString()}`, 18);

      //left align property information
      leftAlign(`${propertyInformation.displayName}`, 10);
      leftAlign(
        `${String(propertyInformation.propertyAddress).split(",")[0]}`,
        14
      );
      leftAlign(
        `${String(propertyInformation.propertyAddress)
          .split(",")
          .slice(1)
          .join(",")}`,
        18
      );

      centerAlign(`Turnable Rooms: ${turnableRooms}`, 25);

      //   pdf.addImage(turnableChartURL, "PNG", 20, 30, chartScale, chartScale / 3);
      //   pdf.addImage(
      //     roomsByItemTypeNeededChartURL,
      //     "PNG",
      //     40 + chartScale,
      //     30,
      //     chartScale,
      //     chartScale / 3
      //   );

      const turnableImages = [
        {
          url: turnableChartURL,
          width: chartScale,
          height: chartScale / 3,
          subtitle: "turnable rooms",
        },
        {
          url: roomsByItemTypeNeededChartURL,
          width: chartScale,
          height: chartScale / 3,
          subtitle: "turnable rooms by item type needed",
        },
      ];
      centerAlignImageArray(turnableImages, 30);

      centerAlign(`Vendor Required Rooms: ${UnTurnableRooms}`, 100);
      const vendorNeededImages = [
        {
          url: vendorChartURL,
          width: chartScale,
          height: chartScale / 3,
          subtitle: "",
        },
      ];
      centerAlignImageArray(vendorNeededImages, 110);

      ///////////////////////////table

      const head = [["Vendor Required", "Rooms Needing Vendor"]];
      //use vendorsList to get keys and compair to rooms array and return room.displayName and join them with ","
      const body = createTableBody(vendorsList, rooms);

      // Define table position
      const yPosition = 150; // Set the y position

      // Add the table to the PDF
      pdf.autoTable({
        head: head,
        body: body,
        startY: yPosition,
        margin: { left: margin, right: margin },
        styles: {
          fillColor: false,
          textColor: 0,
          lineColor: 0,
          lineWidth: 0.1,
          fontSize: 10,
        },
        headStyles: {
          fillColor: false,
          textColor: 0,
          lineColor: 0,
          lineWidth: 0.1,
        },
        theme: "plain",
        columnStyles: {
          0: { cellWidth: 40 },
          //   1: { cellWidth: 100 },
        },
        didDrawCell: (data) => {
          const rowIndex = data.row.index;
          const columnIndex = data.column.index;

          const findMergeHeight = (currentIndex, currentValue, cellHeight) => {
            let totalHeight = cellHeight;
            let nextIndex = currentIndex + 1;

            while (
              nextIndex < data.table.body.length &&
              data.table.body[nextIndex].cells[columnIndex].raw === currentValue
            ) {
              totalHeight +=
                data.table.body[nextIndex].cells[columnIndex].height;
              data.table.body[nextIndex].cells[
                columnIndex
              ].styles.fillColor = false;
              data.table.body[nextIndex].cells[columnIndex].text = "";
              nextIndex++;
            }

            return totalHeight;
          };

          if (rowIndex < data.table.body.length - 1) {
            const currentValue = data.cell.raw;

            if (
              currentValue !== "" &&
              data.table.body[rowIndex + 1].cells[columnIndex].raw ===
                currentValue
            ) {
              const cellWidth = data.cell.width;
              const initialHeight = data.cell.height;
              const cellX = data.cell.x;
              const cellY = data.cell.y;

              const totalHeight = findMergeHeight(
                rowIndex,
                currentValue,
                initialHeight
              );

              data.doc.rect(cellX, cellY, cellWidth, totalHeight, "F");
              data.doc.text(
                currentValue,
                cellX + cellWidth / 2,
                cellY + initialHeight / 2,
                {
                  align: "center",
                  baseline: "middle",
                }
              );
            }
          }
        },
      });
      console.log(listTurnableRooms());

      pdf.addPage();
      // list turnable room numbers
      const turnableRoomsList = listTurnableRooms();
      const turnableRoomsHead = [["Turnable Rooms", "Room Notes"]];
      const turnableRoomsBody = turnableRoomsList.map((room) => [
        room.displayName,
        room.unitNotes,
      ]);

      pdf.autoTable({
        head: turnableRoomsHead,
        body: turnableRoomsBody,
        startY: 10,
        margin: { left: margin, right: margin },
        styles: {
          fillColor: false,
          textColor: 0,
          lineColor: 0,
          lineWidth: 0.1,
          fontSize: 10,
        },
        headStyles: {
          fillColor: false,
          textColor: 0,
          lineColor: 0,
          lineWidth: 0.1,
        },
        theme: "plain",
        columnStyles: {
          0: { cellWidth: 40 },
          //   1: { cellWidth: 100 },
        },
      });

      //////table

      pdf.save("DownRoomsChart.pdf");
      setDisplayChart(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      alert("An error occurred while generating the PDF. Please try again.");
    }
  };

  const turnableRooms = getTurnableRooms();
  const UnTurnableRooms = getUnTurnableRooms();
  const vendorRequiredRooms = getVendorRequiredRooms();
  const roomsByItemTypeNeeded = getRoomsByItemTypeNeeded();
  //   console.log(groupedItems);
  //   console.log(rooms);
  //   console.log(roomsByItemTypeNeeded);
  console.log(currentUser);

  const keypairs = {
    Electrical: {
      value: vendorRequiredRooms.Electrical,
      label: "Electrical Repair",
      color: "yellow",
    },
    PestControl: {
      value: vendorRequiredRooms.PestControl,
      label: "Pest Control",
      color: "blue",
    },
    Plumbing: {
      value: vendorRequiredRooms.Plumbing,
      label: "Plumbing Repair",
      color: "navy",
    },
    TubResurface: {
      value: vendorRequiredRooms.TubResurface,
      label: "Tub Resurface",
      color: "grey",
    },
    Window: {
      value: vendorRequiredRooms.Window,
      label: "Window Replacement",
      color: "purple",
    },
    Subfloor: {
      value: vendorRequiredRooms.Subfloor,
      label: "Subfloor Repair",
      color: "red",
    },
  };

  const turnableData = [
    {
      id: "Turnable Rooms",
      value: turnableRooms,
      color: "green",
      label: `Turnable Rooms: ${turnableRooms}`,
    },
    {
      id: "UnTurnable Rooms",
      value: UnTurnableRooms,
      color: "red",
      label: `Vendor Required: ${UnTurnableRooms}`,
    },
  ];

  const vendorData = Object.keys(vendorRequiredRooms).map((key) => ({
    id: key,
    value: vendorRequiredRooms[key],
    label: `${keypairs[key]?.label}: ${vendorRequiredRooms[key]}` || key,
    color: keypairs[key]?.color || "black",
  }));

  const customTheme = createTheme({
    components: {
      MuiTypography: {},
    },
  });

  return (
    <div>
      <ThemeProvider theme={customTheme}>
        <div
          ref={turnableRef}
          style={{
            position: "absolute",
            left: "-9999px",
            top: "0",
            display: displayChart ? "block" : "none",
          }}
        >
          <PieChart
            width={600}
            height={200}
            series={[
              {
                arcLabel: (item) => `${item.label}`, // (${item.value})`,
                arcLabelMinAngle: 90,
                data: turnableData,
              },
            ]}
          />
        </div>
        <div
          ref={vendorRef}
          style={{
            position: "absolute",
            left: "-9999px",
            top: "0",
            display: displayChart ? "block" : "none",
          }}
        >
          <PieChart
            width={600}
            height={200}
            series={[
              {
                arcLabel: (item) => `${item.label}`, // (${item.value})`,
                arcLabelMinAngle: 90,
                data: vendorData,
              },
            ]}
          />
        </div>
        <div
          ref={roomsByItemTypeNeededRef}
          style={{
            position: "absolute",
            left: "-9999px",
            top: "0",
            display: displayChart ? "block" : "none",
          }}
        >
          <PieChart
            width={675}
            height={225}
            series={[
              {
                arcLabel: (item) => `${item.label}`, // (${item.value})`,
                arcLabelMinAngle: 100,
                data: Object.keys(roomsByItemTypeNeeded).map((key) => ({
                  id: key,
                  value: roomsByItemTypeNeeded[key],
                  label: `${key}: ${roomsByItemTypeNeeded[key]}`,
                  // color: "black",
                })),
              },
            ]}
          />
        </div>
      </ThemeProvider>
      <Button onClick={generatePDF}>Generate Down Rooms Chart</Button>
    </div>
  );
};

export default GenerateRoomsChart;
