import jsPDF from "jspdf";
import { collection, query, getDocs, orderBy, limit } from "firebase/firestore";
import { firestore } from "../firebase";

const GenerateDRListPDF = async (
  selectedProperty,
  rooms,
  setStatusMessage,
  user,
  fileName
) => {
  setStatusMessage("Initializing PDF");
  const pdf = new jsPDF({
    orientation: "portrait", // or 'landscape' depending on your needs
    unit: "mm",
    format: [280, 297], //### Width increased by 70mm to accommodate more content
  });

  const pageWidth = pdf.internal.pageSize.getWidth();
  const margin = 5;
  const maxLineWidth = pageWidth - margin * 2; // Define the maximum width for the text

  const rightAlign = (text, ypos) => {
    const textWidth = pdf.getTextWidth(text);
    const xpos = pageWidth - textWidth - margin;

    pdf.text(text, xpos, ypos);
  };

  const leftAlign = (text, ypos) => {
    const wrappedText = pdf.splitTextToSize(text, maxLineWidth); // Automatically wrap text
    pdf.text(wrappedText, margin, ypos);
  };

  const centerAlign = (text, ypos) => {
    const textWidth = pdf.getTextWidth(text);
    const xpos = (pageWidth - textWidth) / 2;

    pdf.text(text, xpos, ypos);
  };

  pdf.setFontSize(20);
  pdf.setFont("helvetica", "bold");
  centerAlign(fileName, 30);
  pdf.setFontSize(12);
  rightAlign(`Generated by ${user.fullName}`, 10);
  rightAlign(`Date: ${new Date().toLocaleDateString()}`, 15);

  const downRooms = rooms.filter((room) => room.status === "Down");

  centerAlign(`including units`, 50);
  leftAlign(`${downRooms.map((room) => room.displayName).join(", ")}`, 55);

  centerAlign(`total items needed:`, 100);

  //end of front page
  pdf.addPage();

  setStatusMessage(`Generating PDF with ${downRooms.length} rooms`);

  let itemsNeeded = [];

  for (const room of downRooms) {
    let unitSpecificItemsNeeded = [];
    //add room needs to itemsNeeded

    //for each key starting with itemCount, if the value of the key is greater than 0, add the key and value to itemsNeeded
    Object.keys(room).forEach((key) => {
      if (key.includes("itemCount") && room[key] > 0) {
        // if item not in itemsNeeded array Add the item and count to the itemsNeeded array, else, add the count to the existing item in the itemsNeeded array
        const itemIndex = itemsNeeded.findIndex(
          (item) => item.item === key.replace("itemCount", "")
        );
        if (itemIndex !== -1) {
          itemsNeeded[itemIndex].count += room[key];
        } else {
          itemsNeeded.push({
            item: key.replace("itemCount", ""),
            count: room[key],
          });
        }
        unitSpecificItemsNeeded.push({
          item: key.replace("itemCount", ""),
          count: room[key],
        });
      }
    });

    console.log(
      `Items needed for ${room.displayName}: ${JSON.stringify(
        unitSpecificItemsNeeded
      )}`
    ); // Debug: verify items

    // Add room number in bold at the top of the page
    pdf.setFontSize(20);
    pdf.setFont("helvetica", "bold");
    pdf.text(room.displayName, 10, 20);
    if (room.unitNotes) {
      pdf.setFontSize(10); ////##### Changed to 10 from 12
      leftAlign(`Notes: ${room.unitNotes}`, 30);
    }

    console.log("Room added: ", room.displayName);

    // Fetch images for the room
    setStatusMessage(`Fetching images for ${room.displayName}`);
    const imagesRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "units",
      room.id,
      "images"
    );

    const imagesQuerySnapshot = await getDocs(
      query(imagesRef, orderBy("uploadedAt", "desc"), limit(6))
    );
    let imageRow = 50; // Initial position for the first image//##### Changed to 50 from 30
    let imageCol = 10; // Initial position for the first image column
    let imagesCount = 0; // Counter for images per page

    for (const doc of imagesQuerySnapshot.docs) {
      setStatusMessage(`${room.displayName}: Adding image ${imagesCount + 1}`);
      if (imagesCount >= 6) break; // Stop if six images have been added

      const image = doc.data();

      try {
        const response = await fetch(image.url);
        const blob = await response.blob();
        const img = new Image();
        const url = URL.createObjectURL(blob);
        img.src = url;

        await new Promise((resolve) => {
          img.onload = () => {
            pdf.addImage(img, "JPEG", imageCol, imageRow, 80, 80); // Adjust the size as needed
            URL.revokeObjectURL(url);
            pdf.link(imageCol, imageRow, 80, 80, {
              ///##### Added link to the image
              url: image.url,
            });
            imageCol += 90; // Adjust the gap between images as needed

            // Move to next row if two images are in the current row////##### Changed to threee images per row
            if (imageCol > 190) {
              imageCol = 10;
              imageRow += 90;
            }

            imagesCount += 1;
            resolve();
          };
        });
      } catch (error) {
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "normal");
        pdf.text("Issue getting image", imageCol, imageRow + 40);

        imageCol += 90; // Adjust the gap between images as needed

        // Move to next row if two images are in the current row ////##### Changed to threee images per row
        if (imageCol > 190) {
          imageCol = 10;
          imageRow += 90;
        }

        imagesCount += 1;
      }
    }
    ////###added unit specific items to the pdf
    if (unitSpecificItemsNeeded) {
      pdf.setFontSize(10); ////##### Changed to 10 from 12
      leftAlign(
        `Items Needed: ${unitSpecificItemsNeeded
          .sort((a, b) => a.item.localeCompare(b.item))
          .map((item) => `${item.item}: ${item.count}`)
          .join(", ")}`,
        43
      );
    }

    if (downRooms.indexOf(room) !== downRooms.length - 1) {
      pdf.addPage(); // Add new page if not the last room
      console.log("New page added");
    }
  }
  pdf.setPage(1);
  pdf.setFontSize(12);
  leftAlign(
    itemsNeeded
      .sort((a, b) => a.item.localeCompare(b.item))
      .map((item) => `${item.item}: ${item.count}`)
      .join(", "),
    125
  );

  setStatusMessage("Finishing up PDF");

  const today = new Date();
  const date = today.toISOString().split("T")[0];
  const dateStr = date.replace(/-/g, "_");

  const pdfBlob = pdf.output("blob");
  const pdfUrl = URL.createObjectURL(pdfBlob);
  const pdfLink = document.createElement("a");
  pdfLink.href = pdfUrl;
  pdfLink.download = `Generated_DRL_${dateStr}.pdf`;
  pdfLink.click();
};

export default GenerateDRListPDF;
